<template>
    <loading-animation v-if="DataLoading"></loading-animation>
    <div v-else class="m-3">
        <div class="fl-te-c mb-3">
            <h4>Cart Details # 1</h4>
            <div class="btn-group">
                <btn size="sm" @click="$router.push('/sales/marketing/promotions/')" text="Back"/>
            </div>
        </div>
        <div class="card bs-4 p-5 round-2 of-h">
            <h5 class="bg-1 p-3">Details</h5>
            <s-form @submit="onSubmit">
                <validated-input label="Name" name="Name" v-model="model.name" :rules="{required:true}"
                         :disabled="loading"/>
                <textarea-input name="Description"
                                label="Description" v-model="model.description"/>
                <checkbox-input name="is_active" label="Status" v-model="model.is_active"/>

                <validated-vue-select name="Channels" :options="channelOptions"
                                      :rules="{required:true}" label="Channels" v-model="model.channel"/>
                <validated-ajax-vue-select multiple name="Customer Group" :url="groupOptions" :rules="{required:true}"
                                           label="Customer Group" v-model="model.customer_group"/>

                <validated-date-picker label="From" name="From" v-model="model.from"
                                       :disabled="loading"/>
                <validated-date-picker label="To" name="To" v-model="model.to"
                                       :disabled="loading"/>

                <h5 class="mt-4">Conditions</h5>
                <textarea-input name="Description" label="Description" v-model="model.condition_description"/>
                <validated-file-input name="Content" label="Content" v-model="model.condition_content"/>

                <h5 class="mt-4">Actions</h5>
                <validated-input name="Type" label="Type" v-model="model.type"/>
                <validated-input name="Amount" label="Amount" v-model="model.amount"/>
                <validated-input name="max_quantity_discount" label="Maximum Quantity Allowed to be Discounted" v-model="model.max_quantity_discount"/>
                <validated-input name="buy_x_quantity" label="Buy X Quantity" v-model="model.buy_x_quantity"/>
                <checkbox-input name="apply_to_shipping" label="Apply to Shipping" v-model="model.apply_to_shipping"/>
                <checkbox-input name="free_shipping" label="Free Shipping" v-model="model.free_shipping"/>
                <checkbox-input name="end_other_shipping" label="End Other Rules" v-model="model.end_other_shipping"/>
                <btn icon="" text="Save" size="sm" :loading="loading" loading-text="Saving..."/>
            </s-form>
        </div>
    </div>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name : 'DetailsComponent',
    data () {
        return {
            DataLoading    : false,
            cart_rule_id   : this.$route.params.id,
            model          : {},
            loading        : false,
            groupOptions   : urls.salesAdmin.MasterData.Group.vueSelect,
            channelOptions : [
                { value : 'Mobile Application', label : 'Mobile Application' },
                { value : 'Website', label : 'Website' }
            ],
            statusOptions : [
                { value : true, label : 'True' },
                { value : false, label : 'False' }
            ],
            type_options : [
                { label : 'Fixed Amount', value : 'Fixed Amount' },
                { label : 'Buy X Get Y Free', value : 'Buy X Get Y Free' },
                { label : 'Fixed Amount to Whole Cart', value : 'Fixed Amount to Whole Cart' }
            ]
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.salesAdmin.marketing.promotions.cartRule.details,
                { id : that.cart_rule_id });
            const json = response.data;
            that.model = json.data;
            that.DataLoading = false;
        },
        async onSubmit () {
            const that = this;
            const response = await axios.form(urls.salesAdmin.marketing.promotions.cartRule.edit, that.model);
            const json = response.data;
            if (!json.error) {
                that.$notify(json.msg, 'Success', { type : 'success' });
            }
        }
    }
};
</script>

<style scoped>
.label {
    font-weight: 500;
}
</style>
